import { render, staticRenderFns } from "./ConfirmG25.vue?vue&type=template&id=1683cffb&scoped=true&"
import script from "./ConfirmG25.vue?vue&type=script&lang=js&"
export * from "./ConfirmG25.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmG25.vue?vue&type=style&index=0&id=1683cffb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1683cffb",
  null
  
)

export default component.exports