<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-past-coordinate"
      :title="title"
      scrollable
      no-close-on-backdrop
      cancel-variant="outline-secondary"
      hide-footer
      hide-header
      size="lg"
    >
      <div>
        <div class="title mt-2">
          <h4>Copy & Paste your SCALED coordinates below</h4>
        </div>
        <div class="mt-4">
          <b-form-textarea
            id="textarea-state"
            class="past-coordinates-textarea"
            v-model="coordinateText"
            :state="coordinateText.length >= 200 && coordinateText.length <= 300"
            placeholder="Enter coordinates here"
            rows="5"
          />
        </div>
        <div class="mt-2" v-if="invalideCoordinate">
          <small class="text-danger">
            Invalid coordinates. Please enter your coordinates as in the example below.
          </small>
          
          <p class="mt-1">
            Abazin,0.1079895,0.07553,-0.0231456,-0.0175631,-0.0323136,0.0003835,0.0081666,-0.0031151,-0.0499037,
            -0.0222784,-0.0015832,0.0053015,-0.0179136,0.0037846,0.0010858,-0.0114026,0.0085728,-0.0037531,
            -0.0067406,0.0197125,0.009031,0.0006493,-0.0011861,-0.0064315,-0.0026044
          </p>
        </div>
        <div class="text-right mt-2">
          <button class="lab-btn lab-btn-lg mr-1 lab-btn-light" @click="hiddenModal()"> Close </button>
          <button class="lab-btn lab-btn-lg lab-btn-info" @click="handleOk()"> Save </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { textareaToArray } from './coordinateCheck';

export default {
  components: {
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      coordinateText: this.modalObject.item.targetCoordinate,
      /* eslint-disable */
      title: 'Paste \"Scaled\" Coordinates',
      invalideCoordinate: false,
    }
  },
  methods: {
    initialModal() {
      this.$refs['modal-past-coordinate'].show();
    },
    handleOk() {
      let targetCoordinate = this.coordinateText.replaceAll(' ', '');
      const coordinateControlRes = textareaToArray(targetCoordinate);
      if (coordinateControlRes.isSuccess === false) {
        this.invalideCoordinate = true;
        return;
      }

      if (coordinateControlRes.result < 25) {
        this.invalideCoordinate = true;
        return;
      }
      
      if (coordinateControlRes.result === 25) {
        targetCoordinate = Math.floor(Math.random() * 98567263) + '_scaled,' + targetCoordinate;
      }

      const fileItem = this.modalObject.item;
      this.$store.commit('g25Checkout/addOrderCoordinate', { targetCoordinate, fileItem })
      this.hiddenModal();
    },
    hiddenModal() {
      this.$refs['modal-past-coordinate'].hide()
      this.modalObject.showModal = false;
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss" scoped>
.past-coordinates-textarea {
  font-size: 0.8rem;
  border: none;
  background-color: var(--lab-light-house);
}
.past-coordinates-textarea:focus {
  border: 2px solid var(--lab-minted-elegance-color);
  box-shadow: none !important;
}
</style>
