/* eslint-disable */
export const textareaToArray = (textareaData) => {
  const response = {
      isSuccess: true,
      message: '',
      result: [],
  };
  
  let i, j, m, n, text1, text2, diff12,
      text3, diff23, text4, diff34, lines, columnNum;

  text1 = textareaData.trim().replace(/\r\n/g, "\n").replace(/\"/g, "").replace(/\</g, "&lt;").replace(/\>/g, "&gt;");
  text2 = text1.replace(/[^\S\n]/g, "");
  diff12 = text1.length - text2.length;

  text3 = text2.replace(/\n+/g, "\n");
  diff23 = text2.length - text3.length;

  text4 = text3.replace(/\,+/g, "\,");
  diff34 = text3.length - text4.length;
  if (diff34 > 0) {
      response.isSuccess = false;
      return response;
  }
  lines = text4.split("\n");

  if (lines.length > 1) {
      response.isSuccess = false;
      return response;
  }

  columnNum = lines[0].split(",").length;
  if (columnNum === 1) {
      response.isSuccess = false;
      return response;
  }
  for (i = 0, n = lines.length; i < n; i++) {
      lines[i] = lines[i].split(",");
      if (lines[i].length !== columnNum) {
          response.isSuccess = false;
          return response;
      }
      for (j = 1, m = lines[i].length; j < m; j++) {
          if (isNaN(lines[i][j])) {
              response.isSuccess = false;
              return response;
          }
      }
  }

  for (i = 0, n = lines.length; i < n; i++) {
      for (j = 1; j < columnNum; j++) {
          lines[i][j] = Number(lines[i][j]);
      }
  }
  response.result = columnNum;
  return response;
}
