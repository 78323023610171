<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 485.8">
    <defs>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <g>
          <path class="cls-2" d="M270.07,54.26H127.58c-10.36,0-18.76,8.3-18.76,18.55V384.41c0,10.24,8.4,18.55,18.76,18.55h216.67c10.36,0,18.76-8.3,18.76-18.55V146.16l-92.93-91.9Z"/>
          <path class="cls-7" d="M270.07,54.26l62.54,91.9v238.26c0,10.24-8.4,18.55-18.76,18.55h30.39c10.36,0,18.76-8.3,18.76-18.55V146.16l-92.93-91.9Z"/>
          <path class="cls-3" d="M270.07,54.26V122.44c0,13.1,10.74,23.72,23.98,23.72h68.95l-92.93-91.9Z"/>
        </g>
        <g>
          <path class="cls-5" d="M166.8,136.25c0,.1-.02,.19-.05,.25s-.12,.12-.26,.17-.34,.08-.6,.1-.62,.03-1.08,.03c-.38,0-.69,0-.92-.03-.23-.02-.41-.05-.54-.1-.13-.05-.23-.11-.28-.19s-.1-.17-.14-.28l-1.59-3.95c-.19-.45-.38-.84-.56-1.19s-.39-.64-.61-.87-.48-.41-.77-.53-.63-.18-1.01-.18h-1.12v6.75c0,.09-.03,.17-.09,.25-.06,.07-.16,.13-.29,.18-.14,.05-.32,.08-.55,.11-.23,.03-.52,.04-.88,.04s-.64-.01-.87-.04c-.23-.03-.42-.06-.55-.11s-.23-.1-.29-.18-.08-.16-.08-.25v-16.08c0-.4,.1-.69,.31-.87,.21-.18,.46-.27,.76-.27h4.58c.46,0,.85,0,1.15,.03,.3,.02,.57,.04,.82,.07,.71,.1,1.35,.27,1.92,.52s1.05,.57,1.45,.96c.4,.4,.7,.87,.91,1.41,.21,.54,.31,1.17,.31,1.87,0,.59-.08,1.13-.23,1.62s-.37,.92-.66,1.31c-.29,.38-.65,.72-1.08,1-.43,.28-.92,.51-1.46,.68,.26,.13,.51,.28,.75,.46,.23,.18,.45,.4,.66,.66,.21,.26,.4,.56,.58,.89,.18,.33,.36,.71,.53,1.13l1.49,3.49c.14,.35,.23,.6,.27,.76,.05,.16,.07,.29,.07,.38Zm-4.64-12.01c0-.58-.13-1.08-.4-1.48s-.7-.68-1.3-.85c-.18-.04-.39-.08-.62-.11s-.55-.04-.96-.04h-1.61v5.02h1.83c.51,0,.96-.06,1.34-.18,.38-.12,.7-.3,.96-.52,.26-.22,.45-.49,.58-.8s.19-.66,.19-1.04Z"/>
          <path class="cls-5" d="M190.39,135.38c.11,.33,.18,.59,.21,.78s0,.34-.11,.43c-.1,.1-.28,.15-.53,.18-.26,.02-.61,.03-1.05,.03s-.83,0-1.09-.02-.46-.04-.59-.08c-.14-.04-.23-.1-.29-.17s-.1-.17-.14-.29l-1.19-3.56h-6.65l-1.12,3.46c-.04,.13-.08,.24-.14,.32-.06,.09-.16,.15-.29,.21-.13,.05-.32,.08-.56,.1-.24,.02-.56,.03-.95,.03-.42,0-.75-.01-.99-.04s-.4-.09-.49-.2c-.09-.11-.12-.25-.1-.45s.1-.45,.21-.77l5.46-15.71c.05-.15,.12-.28,.19-.38,.07-.1,.19-.17,.35-.22,.16-.05,.38-.08,.66-.1,.28-.01,.66-.02,1.12-.02,.54,0,.97,0,1.29,.02s.57,.05,.75,.1c.18,.05,.31,.13,.38,.23,.08,.1,.14,.24,.2,.41l5.47,15.7Zm-8.13-12.99h-.01l-2.5,7.53h5.02l-2.5-7.53Z"/>
          <path class="cls-5" d="M216.86,135.8c-.05,.22-.13,.39-.23,.53-.1,.13-.24,.23-.43,.3-.19,.07-.44,.11-.74,.14-.3,.02-.68,.03-1.15,.03-.52,0-.94-.01-1.25-.03-.32-.02-.56-.07-.75-.14s-.31-.17-.4-.3c-.08-.13-.15-.31-.19-.53l-2.83-11.22h-.03l-2.68,11.22c-.05,.21-.11,.38-.19,.51-.08,.13-.21,.23-.39,.31s-.42,.12-.72,.14c-.31,.02-.71,.03-1.21,.03-.53,0-.95-.01-1.27-.03-.31-.02-.56-.07-.75-.14-.18-.07-.32-.17-.4-.3-.08-.13-.15-.31-.21-.53l-4.02-15.45c-.08-.33-.13-.59-.14-.77,0-.19,.04-.33,.16-.43,.11-.1,.3-.16,.56-.19,.26-.02,.62-.03,1.09-.03,.43,0,.77,0,1.02,.02s.44,.05,.57,.1c.13,.05,.21,.12,.26,.22,.04,.1,.08,.23,.11,.4l3.11,13.56h.01l3.23-13.46c.04-.17,.08-.31,.14-.42,.06-.11,.16-.19,.3-.25,.14-.06,.33-.1,.58-.12,.25-.02,.58-.03,.99-.03,.44,0,.79,0,1.05,.03,.26,.02,.46,.06,.6,.12,.14,.06,.24,.14,.31,.25,.06,.11,.11,.26,.15,.44l3.34,13.46h.03l3.11-13.52c.03-.15,.06-.27,.11-.38,.04-.1,.13-.19,.26-.25,.13-.06,.31-.1,.55-.12,.24-.02,.57-.03,1-.03,.4,0,.71,.01,.94,.03,.22,.02,.39,.08,.49,.19s.14,.25,.13,.44-.06,.46-.14,.8l-4.06,15.41Z"/>
        </g>
        <path class="cls-5" d="M220,158.3h-73.52c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5h73.52c1.38,0,2.5,1.12,2.5,2.5s-1.12,2.5-2.5,2.5Z"/>
        <circle class="cls-5" cx="236" cy="155.8" r="4"/>
        <circle class="cls-5" cx="251" cy="155.8" r="4"/>
        <path class="cls-4" d="M102.95,55.49v52.33c0,6.48,5.25,11.74,11.74,11.74h26.47c6.48,0,11.74-5.25,11.74-11.74V55.49h7.05c10.46,0,15.69-12.64,8.3-20.03L136.21,3.44c-4.58-4.58-12.01-4.58-16.6,0l-32.02,32.02c-7.39,7.39-2.16,20.03,8.3,20.03h7.05Z"/>
        <g class="cls-1">
          <path class="cls-6" d="M257.09,299.36v-2.49s30.7-.02,30.7-.02v-9.12s.01-2.63,.01-2.63c.45,.2,.9,.32,1.39,.39,.37,.05,.67,.05,1.02,.07h.07s-.01,2.48-.01,2.48v11.31s-33.19,0-33.19,0Z"/>
          <path class="cls-6" d="M286.83,286.13c.28,.06,.56,.13,.86,.17,.41,.08,.79,.1,1.2,.1h.09c2.03,.03,4.04-.84,5.53-2.32,1.49-1.51,2.32-3.5,2.33-5.62,0-2.12-.84-4.11-2.32-5.62-1.51-1.49-3.5-2.32-5.65-2.33h-.04c-2.1,.01-4.09,.84-5.56,2.32-1.49,1.51-2.32,3.5-2.33,5.65,0,3.6,2.5,6.78,5.89,7.66Zm-2.06-11.79c1.11-1.1,2.58-1.75,4.1-1.69,1.54,0,3,.6,4.12,1.71,1.1,1.09,1.68,2.54,1.69,4.1,0,1.54-.6,3-1.71,4.1-1.07,1.06-2.48,1.66-4,1.69h-.06c-.3-.02-.56-.02-.88-.06-.43-.06-.81-.17-1.2-.34-2.19-.82-3.74-2.94-3.76-5.36v-.04c0-1.56,.6-3.02,1.69-4.12Z"/>
          <path class="cls-6" d="M256.58,230.54v2.49s30.7,.02,30.7,.02v9.12s.01,2.63,.01,2.63c.45-.2,.9-.32,1.39-.39,.37-.05,.67-.05,1.02-.07h.07s-.01-2.48-.01-2.48v-11.31s-33.19,0-33.19,0Z"/>
          <path class="cls-6" d="M280.42,251.43c0,2.14,.83,4.14,2.33,5.65,1.47,1.49,3.46,2.31,5.56,2.32h.04c2.14,0,4.14-.83,5.65-2.33,1.49-1.51,2.33-3.5,2.32-5.62,0-2.12-.83-4.12-2.33-5.62-1.49-1.49-3.5-2.35-5.53-2.32h-.09c-.41,0-.79,.02-1.2,.1-.3,.04-.58,.1-.86,.17-3.39,.88-5.89,4.06-5.89,7.66Zm2.14,.01v-.04c.01-2.42,1.57-4.53,3.76-5.36,.39-.17,.77-.27,1.2-.34,.32-.04,.58-.04,.88-.06h.06c1.52,.03,2.93,.64,4,1.69,1.11,1.1,1.72,2.56,1.71,4.1,0,1.56-.59,3.02-1.69,4.1-1.12,1.11-2.58,1.72-4.12,1.71-1.52,.06-3-.59-4.1-1.69-1.09-1.1-1.7-2.56-1.69-4.12Z"/>
          <path class="cls-6" d="M262.3,324.1v-2.48s31.98,.15,31.98,.15l2.51,.02c-.07,.4-.12,.82-.12,1.24,0,.42,.05,.84,.1,1.24l-2.48-.02-31.98-.15Z"/>
          <path class="cls-6" d="M296.01,323.73c.2,1.71,.96,3.33,2.2,4.58,1.47,1.51,3.45,2.36,5.57,2.39h.06c2.1,.01,4.08-.79,5.58-2.25,1.53-1.49,2.38-3.48,2.39-5.6,.05-4.39-3.46-7.99-7.85-8.04h-.04c-2.16-.01-4.16,.85-5.6,2.28-1.23,1.19-2.05,2.75-2.3,4.5-.07,.34-.09,.71-.09,1.07s.02,.73,.08,1.07Zm7.91-6.78h.04c3.19,.04,5.77,2.67,5.72,5.86-.01,1.56-.64,3.02-1.74,4.1-1.1,1.06-2.56,1.65-4.1,1.64h-.02c-1.54-.01-2.99-.64-4.08-1.74-.83-.86-1.38-1.92-1.59-3.05-.04-.34-.08-.71-.08-1.07s.05-.73,.11-1.07c.53-2.67,2.92-4.69,5.73-4.67Z"/>
          <path class="cls-6" d="M161.23,204.44l-.12,11.31-.04,2.48h.07c.35,.03,.65,.03,1.02,.08,.5,.08,.94,.2,1.39,.41l.04-2.63,.11-9.12,30.69,.35,.04-2.48-33.2-.39h0Z"/>
          <polygon class="cls-6" points="289.09 209.96 289.06 212.45 258.03 212.53 258.07 210.05 289.09 209.96"/>
          <path class="cls-6" d="M296.26,205.96c2.95,.12,5.26,2.54,5.26,5.5,0,1.47-.58,2.84-1.62,3.88-1.04,1.03-2.42,1.6-3.89,1.6h-.22c-2.95-.13-5.26-2.54-5.26-5.5,0-1.47,.58-2.84,1.62-3.88,1.04-1.03,2.42-1.6,3.89-1.6h.22m.1-2.48c-.11,0-.21,0-.32,0-4.41,0-7.98,3.55-7.99,7.96,0,4.3,3.38,7.81,7.64,7.99,.11,0,.21,0,.32,0,4.41,0,7.98-3.55,7.99-7.96,0-4.3-3.38-7.81-7.64-7.99h0Z"/>
          <path class="cls-6" d="M163.93,303.36c-.65,.23-1.34,.36-2.06,.36-.01,0-.03,0-.04,0h-.11s-.04,2.2-.04,2.2l-.25,20.84,29.59,.35,.04-2.2-27.38-.33,.21-18.89,.04-2.31h0Z"/>
          <path class="cls-6" d="M165.54,258.58c.05,.4,.09,.82,.09,1.24,0,.42-.06,.84-.13,1.24l2.51,.04,31.98,.36,.02-2.48-31.98-.36-2.48-.04h0Z"/>
          <path class="cls-6" d="M161.89,218.08c-1.91,0-3.8,.81-5.22,2.19-1.47,1.45-2.29,3.38-2.32,5.44-.03,2.06,.76,4,2.19,5.49,1.45,1.47,3.38,2.29,5.46,2.32h.04s.03,0,.05,0c2.02,0,3.94-.78,5.37-2.19,1.47-1.45,2.29-3.38,2.33-5.46,.04-3.5-2.35-6.61-5.63-7.51-.27-.06-.54-.13-.83-.17-.39-.09-.77-.11-1.16-.11h-.08c-.06,0-.12,0-.19,0h0Zm.35,13.37c-.08,0-.16,0-.23,0-1.5,0-2.91-.62-3.98-1.71-1.05-1.07-1.61-2.49-1.6-4.01,.01-1.5,.62-2.91,1.71-3.96,1.05-1.01,2.42-1.59,3.9-1.6h.06c.29,.02,.54,.02,.85,.07,.42,.07,.79,.17,1.16,.34,2.12,.83,3.6,2.9,3.59,5.25v.04c-.01,1.52-.62,2.93-1.69,3.98-1.03,1-2.38,1.6-3.77,1.6h0Z"/>
          <path class="cls-6" d="M158,252.17c-2.06,0-3.99,.79-5.47,2.23-1.51,1.47-2.35,3.43-2.36,5.53-.05,4.34,3.42,7.9,7.76,7.95,0,0,.02,0,.04,0,.02,0,.03,0,.05,0,2.12,0,4.07-.85,5.49-2.25,1.21-1.18,2.03-2.72,2.27-4.45,.07-.34,.09-.7,.09-1.06,0-.36-.02-.72-.08-1.06-.2-1.69-.95-3.29-2.17-4.52-1.45-1.49-3.41-2.33-5.51-2.36h-.06s-.04,0-.05,0h0Zm.02,13.59s-.02,0-.04,0t-.04,0c-3.15-.04-5.7-2.64-5.66-5.79,.01-1.55,.63-2.98,1.72-4.05,1.08-1.04,2.5-1.62,4.01-1.62,.01,0,.03,0,.04,0h.02c1.52,.01,2.96,.63,4.03,1.72,.82,.85,1.36,1.89,1.57,3.02,.04,.34,.08,.7,.08,1.06,0,.36-.05,.72-.11,1.06-.52,2.63-2.87,4.62-5.62,4.62h0Z"/>
          <path class="cls-6" d="M162.49,289.7c-2.13,0-3.97,.78-5.45,2.22-1.47,1.43-2.3,3.37-2.33,5.44-.01,2.07,.77,4.04,2.2,5.51,1.43,1.47,3.33,2.3,5.38,2.33h.08s.04,0,.04,0c.02,0,.04,0,.05,0,.65,0,1.3-.08,1.91-.24,1.3-.33,2.51-.99,3.5-1.96,1.49-1.45,2.32-3.39,2.33-5.46,.03-2.07-.75-4.02-2.2-5.51-1.45-1.47-3.39-2.3-5.46-2.33-.02,0-.04,0-.06,0h0Zm-.04,13.42s-.02,0-.04,0h-.1c-1.48-.03-2.86-.65-3.9-1.7-1.06-1.09-1.63-2.52-1.62-4.02,.03-1.53,.63-2.92,1.72-3.98,1.06-1.05,2.45-1.62,3.94-1.62,.01,0,.03,0,.04,0t.04,0c1.5,.03,2.92,.63,3.98,1.7,1.06,1.09,1.63,2.52,1.6,4.02-.01,1.53-.63,2.94-1.7,4-.59,.56-1.26,.99-2.01,1.26-.62,.22-1.27,.34-1.95,.34h0Z"/>
          <path class="cls-6" d="M211.56,272.15h0c-7.17,0-13.26-5.6-13.88-12.74l-4.7-54.73c-.66-7.65,5.04-14.41,12.69-15.07,.4-.04,.79-.05,1.19-.05,7.17,0,13.26,5.6,13.88,12.74l4.7,54.73c.66,7.65-5.04,14.41-12.69,15.07-.4,.04-.8,.05-1.19,.05Zm0-3h0c.31,0,.62-.01,.93-.04,6.01-.52,10.47-5.82,9.96-11.83l-4.7-54.73c-.48-5.6-5.27-10-10.89-10-.31,0-.62,.01-.93,.04-6.01,.52-10.48,5.82-9.96,11.83l4.7,54.73c.48,5.6,5.27,9.99,10.89,9.99Z"/>
          <path class="cls-6" d="M242.26,272.15h0c-.4,0-.79-.02-1.19-.05-7.65-.66-13.34-7.42-12.69-15.07l4.7-54.73c.65-7.54,7.46-13.37,15.08-12.69,7.64,.66,13.33,7.42,12.68,15.07l-4.7,54.73c-.61,7.14-6.71,12.74-13.88,12.74Zm4.7-79.59c-5.62,0-10.41,4.39-10.89,10l-4.7,54.73c-.51,6,3.95,11.31,9.96,11.83,.32,.03,.63,.04,.93,.04v1.5s0-1.5,0-1.5c5.62,0,10.41-4.39,10.89-10l4.7-54.73c.51-6-3.95-11.31-9.95-11.83-.32-.03-.63-.04-.94-.04Z"/>
          <path class="cls-6" d="M204.92,339.91c-.87,0-1.74-.07-2.62-.23-3.98-.7-7.45-2.91-9.79-6.24-2.33-3.33-3.24-7.35-2.54-11.32l6.55-37.41c1.44-8.24,9.32-13.77,17.56-12.33h0c8.24,1.44,13.77,9.32,12.33,17.56l-6.55,37.41c-1.29,7.36-7.71,12.56-14.94,12.56Zm6.54-64.76c-5.8,0-10.95,4.17-11.99,10.08l-6.55,37.41c-.56,3.19,.17,6.41,2.04,9.08s4.66,4.45,7.85,5.01c6.61,1.16,12.93-3.28,14.09-9.89l6.55-37.41c1.16-6.61-3.28-12.93-9.89-14.09h0c-.71-.12-1.41-.18-2.1-.18Z"/>
          <path class="cls-6" d="M247.64,340.04c-3.21,0-6.33-1.02-8.98-2.97-3.27-2.41-5.41-5.94-6.01-9.93l-5.69-37.55c-1.25-8.27,4.46-16.02,12.73-17.28h0c8.27-1.25,16.02,4.46,17.28,12.73l5.69,37.55c1.25,8.27-4.46,16.02-12.73,17.28-.76,.12-1.53,.17-2.29,.17Zm-7.51-64.76h0c-6.64,1.01-11.22,7.22-10.21,13.86l5.69,37.55c.49,3.2,2.2,6.03,4.83,7.96,2.63,1.93,5.83,2.73,9.03,2.25,3.2-.48,6.03-2.2,7.96-4.83,1.94-2.63,2.73-5.83,2.25-9.04l-5.69-37.55c-1.01-6.63-7.22-11.22-13.86-10.21Z"/>
        </g>
        <g>
          <path class="cls-4" d="M159.8,214.21c.09,.28,.15,.49,.17,.66,.02,.16,0,.28-.09,.36-.08,.08-.23,.13-.45,.15-.21,.02-.51,.03-.88,.03s-.7,0-.91-.02c-.22-.01-.38-.03-.5-.07-.12-.03-.2-.08-.24-.14-.05-.06-.08-.14-.12-.24l-1-2.99h-5.58l-.94,2.91c-.03,.11-.07,.2-.12,.27-.05,.07-.13,.13-.24,.17s-.27,.07-.47,.09-.47,.02-.8,.02c-.35,0-.63-.01-.83-.04-.2-.02-.34-.08-.41-.17s-.1-.21-.08-.37c.02-.16,.08-.38,.17-.64l4.58-13.19c.05-.13,.1-.24,.16-.32s.16-.14,.29-.18,.32-.07,.56-.08c.24-.01,.55-.02,.94-.02,.45,0,.81,0,1.08,.02,.27,.01,.48,.04,.63,.08s.26,.11,.32,.19,.12,.2,.17,.34l4.59,13.18Zm-6.82-10.9h-.01l-2.1,6.32h4.21l-2.1-6.32Z"/>
          <path class="cls-4" d="M177.29,213.08c0,.19,0,.35-.02,.49-.01,.13-.03,.25-.05,.34-.02,.1-.05,.18-.09,.25-.04,.07-.1,.15-.18,.24-.08,.09-.25,.2-.49,.34-.24,.14-.54,.27-.88,.4-.35,.13-.75,.23-1.19,.32-.45,.08-.93,.13-1.45,.13-1.02,0-1.94-.16-2.76-.47-.82-.31-1.52-.78-2.09-1.41-.57-.62-1.01-1.4-1.32-2.34-.31-.93-.46-2.02-.46-3.26s.17-2.39,.51-3.37,.81-1.8,1.41-2.47c.6-.67,1.33-1.17,2.18-1.52,.85-.34,1.78-.52,2.8-.52,.41,0,.81,.03,1.19,.1s.74,.16,1.06,.27,.62,.24,.88,.38c.26,.14,.44,.27,.55,.37,.1,.1,.17,.19,.21,.26,.04,.07,.07,.16,.09,.26,.02,.11,.04,.23,.05,.38,.01,.14,.02,.32,.02,.54,0,.23,0,.43-.02,.59-.01,.16-.04,.29-.08,.39s-.08,.17-.14,.22c-.05,.04-.12,.07-.18,.07-.12,0-.26-.07-.44-.2-.18-.13-.4-.28-.68-.45-.28-.17-.61-.31-1-.45-.39-.13-.85-.2-1.38-.2-.59,0-1.12,.12-1.58,.36-.46,.24-.86,.59-1.18,1.03s-.57,.99-.74,1.62c-.17,.64-.25,1.35-.25,2.15,0,.87,.09,1.63,.27,2.27s.44,1.17,.77,1.58c.33,.41,.74,.72,1.21,.92,.47,.2,1,.3,1.59,.3,.54,0,1-.06,1.39-.19s.73-.27,1-.42,.51-.29,.69-.41c.18-.12,.32-.18,.42-.18,.08,0,.14,.02,.18,.05s.08,.09,.12,.18c.03,.09,.05,.22,.07,.38,.02,.17,.02,.38,.02,.66Z"/>
          <path class="cls-4" d="M196.62,202.78c0,.22,0,.41-.03,.57-.02,.16-.05,.29-.08,.39s-.08,.16-.13,.2c-.05,.04-.11,.06-.18,.06-.1,0-.26-.07-.48-.21-.22-.14-.52-.29-.88-.45-.37-.16-.81-.31-1.32-.45-.51-.14-1.1-.21-1.77-.21-.72,0-1.37,.12-1.95,.37s-1.08,.6-1.49,1.06c-.41,.46-.73,1-.95,1.64-.22,.64-.33,1.35-.33,2.12,0,.85,.11,1.6,.34,2.25,.23,.65,.54,1.19,.95,1.62,.41,.44,.89,.77,1.46,.99,.57,.22,1.2,.33,1.89,.33,.34,0,.67-.04,1-.12,.33-.08,.64-.19,.92-.34v-3.45h-2.81c-.14,0-.25-.09-.32-.26-.08-.17-.12-.46-.12-.88,0-.21,0-.39,.03-.54,.02-.15,.05-.26,.09-.35,.04-.09,.08-.15,.14-.19s.12-.06,.18-.06h5.02c.12,0,.23,.02,.33,.06,.09,.04,.18,.1,.25,.19,.07,.08,.13,.19,.17,.3,.04,.12,.06,.25,.06,.41v5.92c0,.23-.04,.43-.12,.6-.08,.17-.25,.31-.49,.41s-.56,.2-.92,.31c-.37,.11-.75,.2-1.14,.28-.39,.08-.79,.13-1.19,.17s-.81,.06-1.21,.06c-1.19,0-2.25-.17-3.19-.51-.94-.34-1.73-.83-2.38-1.48s-1.15-1.44-1.49-2.37-.52-1.99-.52-3.17,.18-2.31,.55-3.28c.37-.97,.89-1.79,1.56-2.47s1.49-1.2,2.44-1.56c.95-.36,2.02-.54,3.19-.54,.64,0,1.23,.04,1.76,.13s.99,.2,1.38,.32c.39,.13,.72,.26,.98,.41,.26,.15,.44,.27,.55,.38s.18,.26,.22,.45,.07,.48,.07,.87Z"/>
          <path class="cls-4" d="M214.88,201.71c0,.22,0,.41-.03,.57-.02,.16-.05,.28-.09,.38-.04,.1-.09,.17-.14,.21-.06,.05-.12,.07-.19,.07h-3.72v11.98c0,.08-.02,.15-.07,.21-.05,.06-.13,.11-.25,.15-.12,.04-.27,.07-.47,.09-.19,.02-.44,.04-.73,.04s-.53-.01-.73-.04c-.2-.02-.35-.05-.47-.09-.11-.04-.2-.09-.25-.15-.05-.06-.07-.13-.07-.21v-11.98h-3.72c-.08,0-.14-.02-.2-.07-.05-.04-.1-.12-.14-.21-.04-.1-.07-.22-.09-.38-.02-.16-.03-.35-.03-.57s0-.42,.03-.58c.02-.16,.05-.29,.09-.38,.04-.1,.08-.17,.14-.21s.12-.06,.2-.06h10.48c.07,0,.13,.02,.19,.06,.06,.04,.11,.11,.14,.21,.04,.1,.07,.22,.09,.38,.02,.16,.03,.36,.03,.58Z"/>
          <path class="cls-4" d="M232.37,214.21c.09,.28,.15,.49,.17,.66,.02,.16,0,.28-.09,.36-.08,.08-.23,.13-.45,.15-.21,.02-.51,.03-.88,.03s-.7,0-.91-.02c-.22-.01-.38-.03-.5-.07-.12-.03-.2-.08-.24-.14-.05-.06-.08-.14-.12-.24l-1-2.99h-5.58l-.94,2.91c-.03,.11-.07,.2-.12,.27-.05,.07-.13,.13-.24,.17s-.27,.07-.47,.09-.47,.02-.8,.02c-.35,0-.63-.01-.83-.04-.2-.02-.34-.08-.41-.17s-.1-.21-.08-.37c.02-.16,.08-.38,.17-.64l4.58-13.19c.05-.13,.1-.24,.16-.32s.16-.14,.29-.18,.32-.07,.56-.08c.24-.01,.55-.02,.94-.02,.45,0,.81,0,1.08,.02,.27,.01,.48,.04,.63,.08s.26,.11,.32,.19,.12,.2,.17,.34l4.59,13.18Zm-6.82-10.9h-.01l-2.1,6.32h4.21l-2.1-6.32Z"/>
          <path class="cls-4" d="M249.87,213.08c0,.19,0,.35-.02,.49-.01,.13-.03,.25-.05,.34-.02,.1-.05,.18-.09,.25-.04,.07-.1,.15-.18,.24-.08,.09-.25,.2-.49,.34-.24,.14-.54,.27-.88,.4-.35,.13-.75,.23-1.19,.32-.45,.08-.93,.13-1.45,.13-1.02,0-1.94-.16-2.76-.47-.82-.31-1.52-.78-2.09-1.41-.57-.62-1.01-1.4-1.32-2.34-.31-.93-.46-2.02-.46-3.26s.17-2.39,.51-3.37,.81-1.8,1.41-2.47c.6-.67,1.33-1.17,2.18-1.52,.85-.34,1.78-.52,2.8-.52,.41,0,.81,.03,1.19,.1s.74,.16,1.06,.27,.62,.24,.88,.38c.26,.14,.44,.27,.55,.37,.1,.1,.17,.19,.21,.26,.04,.07,.07,.16,.09,.26,.02,.11,.04,.23,.05,.38,.01,.14,.02,.32,.02,.54,0,.23,0,.43-.02,.59-.01,.16-.04,.29-.08,.39s-.08,.17-.14,.22c-.05,.04-.12,.07-.18,.07-.12,0-.26-.07-.44-.2-.18-.13-.4-.28-.68-.45-.28-.17-.61-.31-1-.45-.39-.13-.85-.2-1.38-.2-.59,0-1.12,.12-1.58,.36-.46,.24-.86,.59-1.18,1.03s-.57,.99-.74,1.62c-.17,.64-.25,1.35-.25,2.15,0,.87,.09,1.63,.27,2.27s.44,1.17,.77,1.58c.33,.41,.74,.72,1.21,.92,.47,.2,1,.3,1.59,.3,.54,0,1-.06,1.39-.19s.73-.27,1-.42,.51-.29,.69-.41c.18-.12,.32-.18,.42-.18,.08,0,.14,.02,.18,.05s.08,.09,.12,.18c.03,.09,.05,.22,.07,.38,.02,.17,.02,.38,.02,.66Z"/>
          <path class="cls-4" d="M269.2,202.78c0,.22,0,.41-.03,.57-.02,.16-.05,.29-.08,.39s-.08,.16-.13,.2c-.05,.04-.11,.06-.18,.06-.1,0-.26-.07-.48-.21-.22-.14-.52-.29-.88-.45-.37-.16-.81-.31-1.32-.45-.51-.14-1.1-.21-1.77-.21-.72,0-1.37,.12-1.95,.37s-1.08,.6-1.49,1.06c-.41,.46-.73,1-.95,1.64-.22,.64-.33,1.35-.33,2.12,0,.85,.11,1.6,.34,2.25,.23,.65,.54,1.19,.95,1.62,.41,.44,.89,.77,1.46,.99,.57,.22,1.2,.33,1.89,.33,.34,0,.67-.04,1-.12,.33-.08,.64-.19,.92-.34v-3.45h-2.81c-.14,0-.25-.09-.32-.26-.08-.17-.12-.46-.12-.88,0-.21,0-.39,.03-.54,.02-.15,.05-.26,.09-.35,.04-.09,.08-.15,.14-.19s.12-.06,.18-.06h5.02c.12,0,.23,.02,.33,.06,.09,.04,.18,.1,.25,.19,.07,.08,.13,.19,.17,.3,.04,.12,.06,.25,.06,.41v5.92c0,.23-.04,.43-.12,.6-.08,.17-.25,.31-.49,.41s-.56,.2-.92,.31c-.37,.11-.75,.2-1.14,.28-.39,.08-.79,.13-1.19,.17s-.81,.06-1.21,.06c-1.19,0-2.25-.17-3.19-.51-.94-.34-1.73-.83-2.38-1.48s-1.15-1.44-1.49-2.37-.52-1.99-.52-3.17,.18-2.31,.55-3.28c.37-.97,.89-1.79,1.56-2.47s1.49-1.2,2.44-1.56c.95-.36,2.02-.54,3.19-.54,.64,0,1.23,.04,1.76,.13s.99,.2,1.38,.32c.39,.13,.72,.26,.98,.41,.26,.15,.44,.27,.55,.38s.18,.26,.22,.45,.07,.48,.07,.87Z"/>
          <path class="cls-4" d="M287.45,201.71c0,.22,0,.41-.03,.57-.02,.16-.05,.28-.09,.38-.04,.1-.09,.17-.14,.21-.06,.05-.12,.07-.19,.07h-3.72v11.98c0,.08-.02,.15-.07,.21-.05,.06-.13,.11-.25,.15-.12,.04-.27,.07-.47,.09-.19,.02-.44,.04-.73,.04s-.53-.01-.73-.04c-.2-.02-.35-.05-.47-.09-.11-.04-.2-.09-.25-.15-.05-.06-.07-.13-.07-.21v-11.98h-3.72c-.08,0-.14-.02-.2-.07-.05-.04-.1-.12-.14-.21-.04-.1-.07-.22-.09-.38-.02-.16-.03-.35-.03-.57s0-.42,.03-.58c.02-.16,.05-.29,.09-.38,.04-.1,.08-.17,.14-.21s.12-.06,.2-.06h10.48c.07,0,.13,.02,.19,.06,.06,.04,.11,.11,.14,.21,.04,.1,.07,.22,.09,.38,.02,.16,.03,.36,.03,.58Z"/>
          <path class="cls-4" d="M159.8,270.67c.09,.28,.15,.49,.17,.66,.02,.16,0,.28-.09,.36-.08,.08-.23,.13-.45,.15-.21,.02-.51,.03-.88,.03s-.7,0-.91-.02c-.22-.01-.38-.03-.5-.07-.12-.03-.2-.08-.24-.14-.05-.06-.08-.14-.12-.24l-1-2.99h-5.58l-.94,2.91c-.03,.11-.07,.2-.12,.27-.05,.07-.13,.13-.24,.17s-.27,.07-.47,.09-.47,.02-.8,.02c-.35,0-.63-.01-.83-.04-.2-.02-.34-.08-.41-.17s-.1-.21-.08-.37c.02-.16,.08-.38,.17-.64l4.58-13.19c.05-.13,.1-.24,.16-.32s.16-.14,.29-.18,.32-.07,.56-.08c.24-.01,.55-.02,.94-.02,.45,0,.81,0,1.08,.02,.27,.01,.48,.04,.63,.08s.26,.11,.32,.19,.12,.2,.17,.34l4.59,13.17Zm-6.82-10.9h-.01l-2.1,6.32h4.21l-2.1-6.32Z"/>
          <path class="cls-4" d="M177.29,269.54c0,.19,0,.35-.02,.49-.01,.13-.03,.25-.05,.34-.02,.1-.05,.18-.09,.25-.04,.07-.1,.15-.18,.24-.08,.09-.25,.2-.49,.34-.24,.14-.54,.27-.88,.4-.35,.13-.75,.23-1.19,.32-.45,.08-.93,.13-1.45,.13-1.02,0-1.94-.16-2.76-.47-.82-.31-1.52-.78-2.09-1.41-.57-.62-1.01-1.4-1.32-2.34-.31-.93-.46-2.02-.46-3.26s.17-2.39,.51-3.37c.34-.98,.81-1.8,1.41-2.47,.6-.67,1.33-1.17,2.18-1.52,.85-.34,1.78-.52,2.8-.52,.41,0,.81,.04,1.19,.1,.38,.07,.74,.16,1.06,.27,.33,.11,.62,.24,.88,.38,.26,.15,.44,.27,.55,.37,.1,.1,.17,.19,.21,.26,.04,.07,.07,.16,.09,.26,.02,.11,.04,.23,.05,.38s.02,.33,.02,.54c0,.23,0,.42-.02,.59-.01,.16-.04,.29-.08,.39s-.08,.17-.14,.22-.12,.07-.18,.07c-.12,0-.26-.07-.44-.2-.18-.13-.4-.28-.68-.45-.28-.16-.61-.31-1-.45s-.85-.2-1.38-.2c-.59,0-1.12,.12-1.58,.36-.46,.24-.86,.59-1.18,1.03s-.57,.99-.74,1.62c-.17,.64-.25,1.35-.25,2.15,0,.87,.09,1.63,.27,2.27s.44,1.17,.77,1.58c.33,.41,.74,.72,1.21,.92,.47,.2,1,.3,1.59,.3,.54,0,1-.06,1.39-.19s.73-.27,1-.42,.51-.29,.69-.41c.18-.12,.32-.18,.42-.18,.08,0,.14,.02,.18,.05s.08,.09,.12,.18c.03,.09,.05,.22,.07,.38,.02,.17,.02,.38,.02,.66Z"/>
          <path class="cls-4" d="M196.62,259.24c0,.22,0,.41-.03,.57-.02,.16-.05,.29-.08,.38s-.08,.16-.13,.2c-.05,.04-.11,.06-.18,.06-.1,0-.26-.07-.48-.21-.22-.14-.52-.29-.88-.45-.37-.16-.81-.31-1.32-.45-.51-.14-1.1-.21-1.77-.21-.72,0-1.37,.12-1.95,.37s-1.08,.6-1.49,1.06c-.41,.46-.73,1-.95,1.64-.22,.64-.33,1.35-.33,2.12,0,.85,.11,1.6,.34,2.25,.23,.65,.54,1.19,.95,1.62,.41,.44,.89,.77,1.46,.99,.57,.22,1.2,.33,1.89,.33,.34,0,.67-.04,1-.12,.33-.08,.64-.19,.92-.34v-3.45h-2.81c-.14,0-.25-.09-.32-.26-.08-.17-.12-.46-.12-.88,0-.21,0-.39,.03-.54,.02-.15,.05-.26,.09-.35,.04-.09,.08-.15,.14-.19s.12-.06,.18-.06h5.02c.12,0,.23,.02,.33,.06,.09,.04,.18,.1,.25,.19,.07,.08,.13,.19,.17,.3,.04,.12,.06,.25,.06,.41v5.92c0,.23-.04,.43-.12,.6-.08,.17-.25,.31-.49,.41s-.56,.2-.92,.31c-.37,.11-.75,.2-1.14,.28-.39,.08-.79,.13-1.19,.17s-.81,.06-1.21,.06c-1.19,0-2.25-.17-3.19-.51-.94-.34-1.73-.83-2.38-1.48s-1.15-1.44-1.49-2.37-.52-1.99-.52-3.17,.18-2.31,.55-3.28c.37-.97,.89-1.79,1.56-2.47s1.49-1.2,2.44-1.56c.95-.36,2.02-.54,3.19-.54,.64,0,1.23,.04,1.76,.13s.99,.2,1.38,.32c.39,.13,.72,.26,.98,.41,.26,.15,.44,.27,.55,.38s.18,.26,.22,.45,.07,.48,.07,.87Z"/>
          <path class="cls-4" d="M214.88,258.17c0,.22,0,.41-.03,.57-.02,.16-.05,.28-.09,.38-.04,.1-.09,.17-.14,.21-.06,.05-.12,.07-.19,.07h-3.72v11.98c0,.08-.02,.15-.07,.21-.05,.06-.13,.11-.25,.15-.12,.04-.27,.07-.47,.09-.19,.02-.44,.04-.73,.04s-.53-.01-.73-.04c-.2-.02-.35-.05-.47-.09-.11-.04-.2-.09-.25-.15-.05-.06-.07-.13-.07-.21v-11.98h-3.72c-.08,0-.14-.02-.2-.07s-.1-.12-.14-.21c-.04-.1-.07-.22-.09-.38-.02-.16-.03-.35-.03-.57s0-.42,.03-.59c.02-.16,.05-.29,.09-.38,.04-.1,.08-.17,.14-.21s.12-.06,.2-.06h10.48c.07,0,.13,.02,.19,.06,.06,.04,.11,.11,.14,.21,.04,.1,.07,.22,.09,.38,.02,.16,.03,.36,.03,.59Z"/>
          <path class="cls-4" d="M232.37,270.67c.09,.28,.15,.49,.17,.66,.02,.16,0,.28-.09,.36-.08,.08-.23,.13-.45,.15-.21,.02-.51,.03-.88,.03s-.7,0-.91-.02c-.22-.01-.38-.03-.5-.07-.12-.03-.2-.08-.24-.14-.05-.06-.08-.14-.12-.24l-1-2.99h-5.58l-.94,2.91c-.03,.11-.07,.2-.12,.27-.05,.07-.13,.13-.24,.17s-.27,.07-.47,.09-.47,.02-.8,.02c-.35,0-.63-.01-.83-.04-.2-.02-.34-.08-.41-.17s-.1-.21-.08-.37c.02-.16,.08-.38,.17-.64l4.58-13.19c.05-.13,.1-.24,.16-.32s.16-.14,.29-.18,.32-.07,.56-.08c.24-.01,.55-.02,.94-.02,.45,0,.81,0,1.08,.02,.27,.01,.48,.04,.63,.08s.26,.11,.32,.19,.12,.2,.17,.34l4.59,13.17Zm-6.82-10.9h-.01l-2.1,6.32h4.21l-2.1-6.32Z"/>
          <path class="cls-4" d="M249.87,269.54c0,.19,0,.35-.02,.49-.01,.13-.03,.25-.05,.34-.02,.1-.05,.18-.09,.25-.04,.07-.1,.15-.18,.24-.08,.09-.25,.2-.49,.34-.24,.14-.54,.27-.88,.4-.35,.13-.75,.23-1.19,.32-.45,.08-.93,.13-1.45,.13-1.02,0-1.94-.16-2.76-.47-.82-.31-1.52-.78-2.09-1.41-.57-.62-1.01-1.4-1.32-2.34-.31-.93-.46-2.02-.46-3.26s.17-2.39,.51-3.37c.34-.98,.81-1.8,1.41-2.47,.6-.67,1.33-1.17,2.18-1.52,.85-.34,1.78-.52,2.8-.52,.41,0,.81,.04,1.19,.1,.38,.07,.74,.16,1.06,.27,.33,.11,.62,.24,.88,.38,.26,.15,.44,.27,.55,.37,.1,.1,.17,.19,.21,.26,.04,.07,.07,.16,.09,.26,.02,.11,.04,.23,.05,.38s.02,.33,.02,.54c0,.23,0,.42-.02,.59-.01,.16-.04,.29-.08,.39s-.08,.17-.14,.22-.12,.07-.18,.07c-.12,0-.26-.07-.44-.2-.18-.13-.4-.28-.68-.45-.28-.16-.61-.31-1-.45s-.85-.2-1.38-.2c-.59,0-1.12,.12-1.58,.36-.46,.24-.86,.59-1.18,1.03s-.57,.99-.74,1.62c-.17,.64-.25,1.35-.25,2.15,0,.87,.09,1.63,.27,2.27s.44,1.17,.77,1.58c.33,.41,.74,.72,1.21,.92,.47,.2,1,.3,1.59,.3,.54,0,1-.06,1.39-.19s.73-.27,1-.42,.51-.29,.69-.41c.18-.12,.32-.18,.42-.18,.08,0,.14,.02,.18,.05s.08,.09,.12,.18c.03,.09,.05,.22,.07,.38,.02,.17,.02,.38,.02,.66Z"/>
          <path class="cls-4" d="M269.2,259.24c0,.22,0,.41-.03,.57-.02,.16-.05,.29-.08,.38s-.08,.16-.13,.2c-.05,.04-.11,.06-.18,.06-.1,0-.26-.07-.48-.21-.22-.14-.52-.29-.88-.45-.37-.16-.81-.31-1.32-.45-.51-.14-1.1-.21-1.77-.21-.72,0-1.37,.12-1.95,.37s-1.08,.6-1.49,1.06c-.41,.46-.73,1-.95,1.64-.22,.64-.33,1.35-.33,2.12,0,.85,.11,1.6,.34,2.25,.23,.65,.54,1.19,.95,1.62,.41,.44,.89,.77,1.46,.99,.57,.22,1.2,.33,1.89,.33,.34,0,.67-.04,1-.12,.33-.08,.64-.19,.92-.34v-3.45h-2.81c-.14,0-.25-.09-.32-.26-.08-.17-.12-.46-.12-.88,0-.21,0-.39,.03-.54,.02-.15,.05-.26,.09-.35,.04-.09,.08-.15,.14-.19s.12-.06,.18-.06h5.02c.12,0,.23,.02,.33,.06,.09,.04,.18,.1,.25,.19,.07,.08,.13,.19,.17,.3,.04,.12,.06,.25,.06,.41v5.92c0,.23-.04,.43-.12,.6-.08,.17-.25,.31-.49,.41s-.56,.2-.92,.31c-.37,.11-.75,.2-1.14,.28-.39,.08-.79,.13-1.19,.17s-.81,.06-1.21,.06c-1.19,0-2.25-.17-3.19-.51-.94-.34-1.73-.83-2.38-1.48s-1.15-1.44-1.49-2.37-.52-1.99-.52-3.17,.18-2.31,.55-3.28c.37-.97,.89-1.79,1.56-2.47s1.49-1.2,2.44-1.56c.95-.36,2.02-.54,3.19-.54,.64,0,1.23,.04,1.76,.13s.99,.2,1.38,.32c.39,.13,.72,.26,.98,.41,.26,.15,.44,.27,.55,.38s.18,.26,.22,.45,.07,.48,.07,.87Z"/>
          <path class="cls-4" d="M287.45,258.17c0,.22,0,.41-.03,.57-.02,.16-.05,.28-.09,.38-.04,.1-.09,.17-.14,.21-.06,.05-.12,.07-.19,.07h-3.72v11.98c0,.08-.02,.15-.07,.21-.05,.06-.13,.11-.25,.15-.12,.04-.27,.07-.47,.09-.19,.02-.44,.04-.73,.04s-.53-.01-.73-.04c-.2-.02-.35-.05-.47-.09-.11-.04-.2-.09-.25-.15-.05-.06-.07-.13-.07-.21v-11.98h-3.72c-.08,0-.14-.02-.2-.07s-.1-.12-.14-.21c-.04-.1-.07-.22-.09-.38-.02-.16-.03-.35-.03-.57s0-.42,.03-.59c.02-.16,.05-.29,.09-.38,.04-.1,.08-.17,.14-.21s.12-.06,.2-.06h10.48c.07,0,.13,.02,.19,.06,.06,.04,.11,.11,.14,.21,.04,.1,.07,.22,.09,.38,.02,.16,.03,.36,.03,.59Z"/>
          <path class="cls-4" d="M159.8,323.67c.09,.28,.15,.49,.17,.66,.02,.16,0,.28-.09,.36-.08,.08-.23,.13-.45,.15-.21,.02-.51,.03-.88,.03s-.7,0-.91-.02c-.22-.01-.38-.03-.5-.07-.12-.03-.2-.08-.24-.14-.05-.06-.08-.14-.12-.24l-1-2.99h-5.58l-.94,2.91c-.03,.11-.07,.2-.12,.27-.05,.07-.13,.13-.24,.17s-.27,.07-.47,.09-.47,.02-.8,.02c-.35,0-.63-.01-.83-.04-.2-.02-.34-.08-.41-.17s-.1-.21-.08-.37c.02-.16,.08-.38,.17-.64l4.58-13.19c.05-.13,.1-.24,.16-.32s.16-.14,.29-.18,.32-.07,.56-.08c.24-.01,.55-.02,.94-.02,.45,0,.81,0,1.08,.02,.27,.01,.48,.04,.63,.08s.26,.11,.32,.19,.12,.2,.17,.34l4.59,13.17Zm-6.82-10.9h-.01l-2.1,6.32h4.21l-2.1-6.32Z"/>
          <path class="cls-4" d="M177.29,322.54c0,.19,0,.35-.02,.49-.01,.13-.03,.25-.05,.34-.02,.1-.05,.18-.09,.25-.04,.07-.1,.15-.18,.24-.08,.09-.25,.2-.49,.34-.24,.14-.54,.27-.88,.4-.35,.13-.75,.23-1.19,.32-.45,.08-.93,.13-1.45,.13-1.02,0-1.94-.16-2.76-.47-.82-.31-1.52-.78-2.09-1.41-.57-.62-1.01-1.4-1.32-2.34-.31-.93-.46-2.02-.46-3.26s.17-2.39,.51-3.37c.34-.98,.81-1.8,1.41-2.47,.6-.67,1.33-1.17,2.18-1.52,.85-.34,1.78-.52,2.8-.52,.41,0,.81,.04,1.19,.1,.38,.07,.74,.16,1.06,.27,.33,.11,.62,.24,.88,.38,.26,.15,.44,.27,.55,.37,.1,.1,.17,.19,.21,.26,.04,.07,.07,.16,.09,.26,.02,.11,.04,.23,.05,.38s.02,.33,.02,.54c0,.23,0,.42-.02,.59-.01,.16-.04,.29-.08,.39s-.08,.17-.14,.22-.12,.07-.18,.07c-.12,0-.26-.07-.44-.2-.18-.13-.4-.28-.68-.45-.28-.16-.61-.31-1-.45s-.85-.2-1.38-.2c-.59,0-1.12,.12-1.58,.36-.46,.24-.86,.59-1.18,1.03s-.57,.99-.74,1.62c-.17,.64-.25,1.35-.25,2.15,0,.87,.09,1.63,.27,2.27s.44,1.17,.77,1.58c.33,.41,.74,.72,1.21,.92,.47,.2,1,.3,1.59,.3,.54,0,1-.06,1.39-.19s.73-.27,1-.42,.51-.29,.69-.41c.18-.12,.32-.18,.42-.18,.08,0,.14,.02,.18,.05s.08,.09,.12,.18c.03,.09,.05,.22,.07,.38,.02,.17,.02,.38,.02,.66Z"/>
          <path class="cls-4" d="M196.62,312.24c0,.22,0,.41-.03,.57-.02,.16-.05,.29-.08,.38s-.08,.16-.13,.2c-.05,.04-.11,.06-.18,.06-.1,0-.26-.07-.48-.21-.22-.14-.52-.29-.88-.45-.37-.16-.81-.31-1.32-.45-.51-.14-1.1-.21-1.77-.21-.72,0-1.37,.12-1.95,.37s-1.08,.6-1.49,1.06c-.41,.46-.73,1-.95,1.64-.22,.64-.33,1.35-.33,2.12,0,.85,.11,1.6,.34,2.25,.23,.65,.54,1.19,.95,1.62,.41,.44,.89,.77,1.46,.99,.57,.22,1.2,.33,1.89,.33,.34,0,.67-.04,1-.12,.33-.08,.64-.19,.92-.34v-3.45h-2.81c-.14,0-.25-.09-.32-.26-.08-.17-.12-.46-.12-.88,0-.21,0-.39,.03-.54,.02-.15,.05-.26,.09-.35,.04-.09,.08-.15,.14-.19s.12-.06,.18-.06h5.02c.12,0,.23,.02,.33,.06,.09,.04,.18,.1,.25,.19,.07,.08,.13,.19,.17,.3,.04,.12,.06,.25,.06,.41v5.92c0,.23-.04,.43-.12,.6-.08,.17-.25,.31-.49,.41s-.56,.2-.92,.31c-.37,.11-.75,.2-1.14,.28-.39,.08-.79,.13-1.19,.17s-.81,.06-1.21,.06c-1.19,0-2.25-.17-3.19-.51-.94-.34-1.73-.83-2.38-1.48s-1.15-1.44-1.49-2.37-.52-1.99-.52-3.17,.18-2.31,.55-3.28c.37-.97,.89-1.79,1.56-2.47s1.49-1.2,2.44-1.56c.95-.36,2.02-.54,3.19-.54,.64,0,1.23,.04,1.76,.13s.99,.2,1.38,.32c.39,.13,.72,.26,.98,.41,.26,.15,.44,.27,.55,.38s.18,.26,.22,.45,.07,.48,.07,.87Z"/>
          <path class="cls-4" d="M214.88,311.17c0,.22,0,.41-.03,.57-.02,.16-.05,.28-.09,.38-.04,.1-.09,.17-.14,.21-.06,.05-.12,.07-.19,.07h-3.72v11.98c0,.08-.02,.15-.07,.21-.05,.06-.13,.11-.25,.15-.12,.04-.27,.07-.47,.09-.19,.02-.44,.04-.73,.04s-.53-.01-.73-.04c-.2-.02-.35-.05-.47-.09-.11-.04-.2-.09-.25-.15-.05-.06-.07-.13-.07-.21v-11.98h-3.72c-.08,0-.14-.02-.2-.07s-.1-.12-.14-.21c-.04-.1-.07-.22-.09-.38-.02-.16-.03-.35-.03-.57s0-.42,.03-.59c.02-.16,.05-.29,.09-.38,.04-.1,.08-.17,.14-.21s.12-.06,.2-.06h10.48c.07,0,.13,.02,.19,.06,.06,.04,.11,.11,.14,.21,.04,.1,.07,.22,.09,.38,.02,.16,.03,.36,.03,.59Z"/>
          <path class="cls-4" d="M232.37,323.67c.09,.28,.15,.49,.17,.66,.02,.16,0,.28-.09,.36-.08,.08-.23,.13-.45,.15-.21,.02-.51,.03-.88,.03s-.7,0-.91-.02c-.22-.01-.38-.03-.5-.07-.12-.03-.2-.08-.24-.14-.05-.06-.08-.14-.12-.24l-1-2.99h-5.58l-.94,2.91c-.03,.11-.07,.2-.12,.27-.05,.07-.13,.13-.24,.17s-.27,.07-.47,.09-.47,.02-.8,.02c-.35,0-.63-.01-.83-.04-.2-.02-.34-.08-.41-.17s-.1-.21-.08-.37c.02-.16,.08-.38,.17-.64l4.58-13.19c.05-.13,.1-.24,.16-.32s.16-.14,.29-.18,.32-.07,.56-.08c.24-.01,.55-.02,.94-.02,.45,0,.81,0,1.08,.02,.27,.01,.48,.04,.63,.08s.26,.11,.32,.19,.12,.2,.17,.34l4.59,13.17Zm-6.82-10.9h-.01l-2.1,6.32h4.21l-2.1-6.32Z"/>
          <path class="cls-4" d="M249.87,322.54c0,.19,0,.35-.02,.49-.01,.13-.03,.25-.05,.34-.02,.1-.05,.18-.09,.25-.04,.07-.1,.15-.18,.24-.08,.09-.25,.2-.49,.34-.24,.14-.54,.27-.88,.4-.35,.13-.75,.23-1.19,.32-.45,.08-.93,.13-1.45,.13-1.02,0-1.94-.16-2.76-.47-.82-.31-1.52-.78-2.09-1.41-.57-.62-1.01-1.4-1.32-2.34-.31-.93-.46-2.02-.46-3.26s.17-2.39,.51-3.37c.34-.98,.81-1.8,1.41-2.47,.6-.67,1.33-1.17,2.18-1.52,.85-.34,1.78-.52,2.8-.52,.41,0,.81,.04,1.19,.1,.38,.07,.74,.16,1.06,.27,.33,.11,.62,.24,.88,.38,.26,.15,.44,.27,.55,.37,.1,.1,.17,.19,.21,.26,.04,.07,.07,.16,.09,.26,.02,.11,.04,.23,.05,.38s.02,.33,.02,.54c0,.23,0,.42-.02,.59-.01,.16-.04,.29-.08,.39s-.08,.17-.14,.22-.12,.07-.18,.07c-.12,0-.26-.07-.44-.2-.18-.13-.4-.28-.68-.45-.28-.16-.61-.31-1-.45s-.85-.2-1.38-.2c-.59,0-1.12,.12-1.58,.36-.46,.24-.86,.59-1.18,1.03s-.57,.99-.74,1.62c-.17,.64-.25,1.35-.25,2.15,0,.87,.09,1.63,.27,2.27s.44,1.17,.77,1.58c.33,.41,.74,.72,1.21,.92,.47,.2,1,.3,1.59,.3,.54,0,1-.06,1.39-.19s.73-.27,1-.42,.51-.29,.69-.41c.18-.12,.32-.18,.42-.18,.08,0,.14,.02,.18,.05s.08,.09,.12,.18c.03,.09,.05,.22,.07,.38,.02,.17,.02,.38,.02,.66Z"/>
          <path class="cls-4" d="M269.2,312.24c0,.22,0,.41-.03,.57-.02,.16-.05,.29-.08,.38s-.08,.16-.13,.2c-.05,.04-.11,.06-.18,.06-.1,0-.26-.07-.48-.21-.22-.14-.52-.29-.88-.45-.37-.16-.81-.31-1.32-.45-.51-.14-1.1-.21-1.77-.21-.72,0-1.37,.12-1.95,.37s-1.08,.6-1.49,1.06c-.41,.46-.73,1-.95,1.64-.22,.64-.33,1.35-.33,2.12,0,.85,.11,1.6,.34,2.25,.23,.65,.54,1.19,.95,1.62,.41,.44,.89,.77,1.46,.99,.57,.22,1.2,.33,1.89,.33,.34,0,.67-.04,1-.12,.33-.08,.64-.19,.92-.34v-3.45h-2.81c-.14,0-.25-.09-.32-.26-.08-.17-.12-.46-.12-.88,0-.21,0-.39,.03-.54,.02-.15,.05-.26,.09-.35,.04-.09,.08-.15,.14-.19s.12-.06,.18-.06h5.02c.12,0,.23,.02,.33,.06,.09,.04,.18,.1,.25,.19,.07,.08,.13,.19,.17,.3,.04,.12,.06,.25,.06,.41v5.92c0,.23-.04,.43-.12,.6-.08,.17-.25,.31-.49,.41s-.56,.2-.92,.31c-.37,.11-.75,.2-1.14,.28-.39,.08-.79,.13-1.19,.17s-.81,.06-1.21,.06c-1.19,0-2.25-.17-3.19-.51-.94-.34-1.73-.83-2.38-1.48s-1.15-1.44-1.49-2.37-.52-1.99-.52-3.17,.18-2.31,.55-3.28c.37-.97,.89-1.79,1.56-2.47s1.49-1.2,2.44-1.56c.95-.36,2.02-.54,3.19-.54,.64,0,1.23,.04,1.76,.13s.99,.2,1.38,.32c.39,.13,.72,.26,.98,.41,.26,.15,.44,.27,.55,.38s.18,.26,.22,.45,.07,.48,.07,.87Z"/>
          <path class="cls-4" d="M287.45,311.17c0,.22,0,.41-.03,.57-.02,.16-.05,.28-.09,.38-.04,.1-.09,.17-.14,.21-.06,.05-.12,.07-.19,.07h-3.72v11.98c0,.08-.02,.15-.07,.21-.05,.06-.13,.11-.25,.15-.12,.04-.27,.07-.47,.09-.19,.02-.44,.04-.73,.04s-.53-.01-.73-.04c-.2-.02-.35-.05-.47-.09-.11-.04-.2-.09-.25-.15-.05-.06-.07-.13-.07-.21v-11.98h-3.72c-.08,0-.14-.02-.2-.07s-.1-.12-.14-.21c-.04-.1-.07-.22-.09-.38-.02-.16-.03-.35-.03-.57s0-.42,.03-.59c.02-.16,.05-.29,.09-.38,.04-.1,.08-.17,.14-.21s.12-.06,.2-.06h10.48c.07,0,.13,.02,.19,.06,.06,.04,.11,.11,.14,.21,.04,.1,.07,.22,.09,.38,.02,.16,.03,.36,.03,.59Z"/>
        </g>
        <path class="cls-5" d="M467.5,408.8c-6.89,0-12.5,5.61-12.5,12.5,0,2.98,1.05,5.72,2.81,7.87l-9.64,9.63h-187.14v-30h-4v34h192.8l10.96-10.97c1.94,1.25,4.24,1.97,6.71,1.97,6.89,0,12.5-5.61,12.5-12.5s-5.61-12.5-12.5-12.5Zm0,21c-4.69,0-8.5-3.81-8.5-8.5s3.81-8.5,8.5-8.5,8.5,3.81,8.5,8.5-3.81,8.5-8.5,8.5Z"/>
        <path class="cls-5" d="M230.32,408.8v30H14.5v-24.16c5.94-.96,10.5-6.13,10.5-12.34,0-6.89-5.61-12.5-12.5-12.5s-12.5,5.61-12.5,12.5c0,6.21,4.56,11.38,10.5,12.34v28.16H234.32v-34h-4Zm-226.32-6.5c0-4.69,3.81-8.5,8.5-8.5s8.5,3.81,8.5,8.5-3.81,8.5-8.5,8.5-8.5-3.81-8.5-8.5Z"/>
        <path class="cls-4" d="M247.6,408.8h-4v42.19l-11.52,12.38c-2.1-1.61-4.73-2.57-7.58-2.57-6.89,0-12.5,5.61-12.5,12.5s5.61,12.5,12.5,12.5,12.5-5.61,12.5-12.5c0-2.6-.8-5.02-2.17-7.02l12.77-13.72v-43.76Zm-23.1,73c-4.69,0-8.5-3.81-8.5-8.5s3.81-8.5,8.5-8.5,8.5,3.81,8.5,8.5-3.81,8.5-8.5,8.5Z"/>
        <path class="cls-5" d="M391.11,9.02c-4.9,0-8.89,3.99-8.89,8.89,0,1.82,.55,3.51,1.49,4.92l-63.71,62,2.79,2.87,63.82-62.13c1.32,.78,2.86,1.23,4.5,1.23,4.9,0,8.89-3.99,8.89-8.89s-3.99-8.89-8.89-8.89Zm0,13.78c-2.7,0-4.89-2.19-4.89-4.89s2.19-4.89,4.89-4.89,4.89,2.19,4.89,4.89-2.19,4.89-4.89,4.89Z"/>
        <path class="cls-5" d="M354.11,4.39c-4.9,0-8.89,3.99-8.89,8.89,0,4.13,2.82,7.61,6.63,8.61v11.11l-43.58,43.58,2.83,2.83,44.75-44.76v-12.64c4.07-.81,7.15-4.42,7.15-8.73,0-4.9-3.99-8.89-8.89-8.89Zm0,13.79c-2.7,0-4.89-2.2-4.89-4.9s2.19-4.89,4.89-4.89,4.89,2.2,4.89,4.89-2.19,4.9-4.89,4.9Z"/>
        <path class="cls-5" d="M385.89,56.02c-1.59,0-3.07,.42-4.36,1.15l-6.07-6.07-45.11,45.11,2.83,2.83,42.28-42.28,3.11,3.11c-.99,1.43-1.57,3.17-1.57,5.04,0,4.9,3.99,8.89,8.89,8.89s8.89-3.99,8.89-8.89-3.99-8.89-8.89-8.89Zm0,13.78c-2.7,0-4.89-2.19-4.89-4.89s2.19-4.89,4.89-4.89,4.89,2.19,4.89,4.89-2.19,4.89-4.89,4.89Z"/>
        <path class="cls-4" d="M400,371.8c-6.62,0-12,5.38-12,12,0,5.94,4.33,10.88,10,11.83v13.17h-133.33v4h137.33v-17.17c5.67-.95,10-5.89,10-11.83,0-6.62-5.38-12-12-12Zm0,20c-4.41,0-8-3.59-8-8s3.59-8,8-8,8,3.59,8,8-3.59,8-8,8Z"/>
        <path class="cls-4" d="M75.83,408.8c-.95-5.67-5.89-10-11.83-10-6.62,0-12,5.38-12,12s5.38,12,12,12c5.94,0,10.88-4.33,11.83-10H225.17v-4H75.83Zm-11.83,10c-4.41,0-8-3.59-8-8s3.59-8,8-8,8,3.59,8,8-3.59,8-8,8Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>
svg {
  height: 300px;
}

.cls-1 {
  opacity: .51;
}

.cls-2 {
  fill: #f7fafa;
}

.cls-3 {
  fill: #e8ebeb;
}

.cls-4 {
  fill: #2d2d2d;
}

.cls-5 {
  fill: #6fceaa;
}

.cls-6 {
  fill: #cecece;
}

.cls-7 {
  fill: #f2f5f5;
}
</style>
