<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.11 18.11">
    <defs>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <path class="cls-1" d="M16.82,5.17h-2.59v1.29h2.59v1.29h1.29v-1.29c0-.71-.58-1.29-1.29-1.29Z"/>
        <rect class="cls-1" x="16.82" y="9.06" width="1.29" height="3.88"/>
        <path class="cls-1" d="M16.82,16.82h-1.29v1.29h1.29c.71,0,1.29-.58,1.29-1.29v-2.59h-1.29v2.59Z"/>
        <rect class="cls-1" width="12.94" height="12.94" rx="1.29" ry="1.29"/>
        <rect class="cls-1" x="10.35" y="16.82" width="3.88" height="1.29"/>
        <path class="cls-1" d="M6.47,14.23h-1.29v2.59c0,.71,.58,1.29,1.29,1.29h2.59v-1.29h-2.59v-2.59Z"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>
svg {
  height: 18px;
}  
.cls-1 {
  fill: #fff;
}
</style>
